<template>
    <b-modal id="modal-detail-stock-opname" hide-header hide-footer ok-only ok-title="Accept" centered size="lg"
        title="Large Modal" class="custom__modal">
        <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
            <b-row>
                <b-col cols="3" class="d-flex align-items-center">
                    <div style="cursor: pointer;" @click="$bvModal.hide('modal-detail-stock-opname')">
                        <feather-icon size="24" class="text-dark font-weight-bolder" icon="ArrowLeftIcon" />
                        <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">
                            Kembali
                        </span>
                    </div>
                </b-col>
                <b-col cols="6" class="d-flex justify-content-center align-items-center">
                    <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
                        Lihat Detail
                    </h1>
                </b-col>
                <b-col cols="3" class="text-right" v-if="!$store.state.stockOpname.isDetail">
                    <b-button class="px-3" @click.prevent="approval(detailResult.uuid, 'approved')">
                        <b-spinner small v-if="isLoading" />
                        Approve
                    </b-button>
                    <b-button class="px-3 ml-1" @click.prevent="approval(detailResult.uuid, 'rejected')">
                        <b-spinner small v-if="isLoading" />
                        Reject
                    </b-button>
                </b-col>
            </b-row>
        </header>
        <b-row class="mx-0 mt-5 pt-5 justify-content-center">
            <b-col cols="12" lg="6">
                <div class="mb-2">
                    <label>Tanggal Stok Opname</label>
                    <b-col cols="12" class="custom__input mb-2 pt-1">
                        {{ detailResult.date }}
                    </b-col>
                </div>

                <div class="mb-2">
                    <label>Lokasi SKU</label>
                    <b-col cols="12" class="custom__input pt-1">
                        {{ detailResult.warehouse ? detailResult.warehouse.name : '-' }}
                    </b-col>
                </div>

                <div class="mb-2">
                    <label>Catatan</label>
                    <b-col cols="12" class="custom__input mb-2 pt-1" style="height: 91.2px;">
                        {{ detailResult.note }}
                    </b-col>
                </div>

                <div>
                    <b-row class="m-0">
                        <b-col cols="6" class="m-0 p-0">
                            <label>Produk</label>
                        </b-col>
                        <b-col cols="2" class="ml-2 p-0">
                            <label>Qty</label>
                        </b-col>
                    </b-row>
                </div>

                <div class="mb-2">
                    <b-row class="m-0" v-for="(item, index) in detailResult.items" :key="index">
                        <b-col cols="6" class="custom__input mb-2 pt-1">
                            {{ item.product.name }}
                        </b-col>
                        <b-col cols="2" class="custom__input mb-2 pt-1 ml-2">
                            {{ item.qty }}
                        </b-col>
                    </b-row>
                </div>

            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import {
    BButton, BModal, BRow, BCol, BSpinner, BImg, BFormGroup
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
    components: {
        BButton,
        BModal,
        BRow,
        BCol,
        BSpinner,
        BImg,
        BFormGroup
    },
    props: {
        getDetail: {
            type: Function
        },
        getData: {
            type: Function,
        },
        getDataApproval: {
            type: Function,
        },
        detailResult: {
            type: Object
        },
    },
    setup() {
        return {
            checkPermission, successNotification, errorNotification,
        }
    },
    data() {
        return {
            detailResultOpname: {},
            isLoading: false,
            isDetail: false,
        }
    },
    watch: {
        '$store.state.stockOpname.isDetail': function (value) {
            this.isDetail = value
        },
        detailResult(value) {
            this.detailResultOpname = value;
        },
    },
    methods: {
        approval(id, status) {
            this.$store.dispatch('stockOpname/postData', {
                type: 'set-status',
                payload: {
                    uuid: id,
                    status,
                },
            }).then(() => {
                this.getData()
                this.getDataApproval()
                this.$bvModal.hide('modal-detail-stock-opname')
                successNotification(this, 'Success', `Produk opname berhasil di ${status}`)
            }).catch(err => {
                // eslint-disable-next-line no-console
                console.log(err)
            })
        },
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

#modal-detail-stock-opname {
    .modal-dialog {
        max-width: 100%;
        margin: 0;

        .modal-content {
            height: 100vh;

            .modal-body {
                padding: 0;
                overflow-y: scroll;

                .progress-bar-success {
                    background-color: #EFF1F5;

                    .progress-bar {
                        background: linear-gradient(269.89deg, #6CDFD4 0%, #45B6AB 98.81%) !important;
                        box-shadow: 0px 6px 8px rgba(44, 129, 120, 0.06);
                        border-radius: 20px;
                    }
                }

                label {
                    font-size: 14px;
                    color: $dark;
                    font-weight: 600;
                }

                .vs__open-indicator {
                    fill: rgba(60, 60, 60, 0.5);
                }

                .custom__input {
                    height: 52px;
                    background: #EFF1F5;
                    border: 1px solid #E4E5EC;
                    border-radius: 8px;
                }

                .vs__dropdown-toggle {
                    height: 52px;
                    background-color: #EFF1F5;
                    border: 1px solid #E4E5EC;
                    border-radius: 8px;
                }
            }
        }
    }
}
</style>