<template>
  <b-modal
    id="modal-add-stock-opname"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal"
  >
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <b-row>
        <b-col
          cols="3"
          class="d-flex align-items-center"
        >
          <div
            style="cursor: pointer;"
            @click="$bvModal.hide('modal-add-stock-opname')"
          >
            <feather-icon
              size="24"
              class="text-dark font-weight-bolder"
              icon="ArrowLeftIcon"
            />
            <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">
              {{ isDetail ? 'Kembali' : 'Batal' }}
            </span>
          </div>
        </b-col>
        <b-col
          cols="6"
          class="d-flex justify-content-center align-items-center"
        >
          <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
            {{ isDetail ? 'Lihat Detail' : 'Tambahkan Stok Opname' }}
          </h1>
        </b-col>
        <b-col
          v-if="checkPermission('add stock opname') && !$store.state.stockOpname.isDetail"
          cols="3"
          class="text-right"
        >
          <b-button
            class="px-3"
            :disabled="isLoading"
            @click.prevent="submit"
          >
            <b-spinner small v-if="isLoading" />
            Simpan
          </b-button>
        </b-col>
      </b-row>
    </header>
    <b-row
      class="mx-0 mt-5 pt-5 justify-content-center"
    >
      <b-col
        cols="12"
        lg="6"
      >
        <FormStokOpname 
          :form-data="formPayload" 
          :is-detail="isDetail" 
          @payload="getPayload" 
          :messages="messages" 
        />
      </b-col>
      <!-- <b-col
        cols="12"
        lg="5"
        :class="!$store.state.stockOpname.resultProduct.uuid ? 'align-self-center' : ''"
      >
        <Rincian v-if="$store.state.stockOpname.resultProduct.uuid" />
        <div class="d-flex justify-content-center align-items-center" v-else>
          <div class="text-center">
            <b-img
              :src="emptyCart"
              width="135"
            />
            <h1 class="h5 text-dark fw-bold-800 size14 mt-2">
              Belum ada data yang ditampilkan
            </h1>
          </div>
        </div>

      </b-col> -->
      <!-- <b-col :lg="step == 2 ? '12' : step == 4 ? '8' : '5'">
        <keep-alive>
          <component :is="comp" />
        </keep-alive>
      </b-col> -->
    </b-row>
  </b-modal>
</template>

<script>
import {
  BButton, BModal, BRow, BCol, BSpinner, BImg,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Rincian from '@/components/Stock/Opname/PartModal/Rincian.vue'
import FormStokOpname from '@/components/Stock/Opname/PartModal/FormStokOpname.vue'

import emptyCart from '@/assets/images/empty-cart.png'

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    Rincian,
    FormStokOpname,
    BSpinner,
    BImg,
  },
  props: {
    getData: {
      type: Function,
    },
    getDataApproval: {
      type: Function,
    },
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      emptyCart,
      comp: 'FormAddIncomingProduct',
      isLoading: false,
      formPayload: {},
      messages: '',
      isDetail: false,
    }
  },
  watch: {
    '$store.state.stockOpname.isDetail': function (value) {
      this.isDetail = value
    },
    '$store.state.stockOpname.detailItem': {
      handler(value) {
        if (value) {
          this.formPayload = {
            product_uuid: value.product.uuid,
            warehouse_uuid: value.warehouse.uuid,
            qty: value.qty,
            date: value.qty,
            note: '',
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
  },
  methods: {
    getPayload(value) {
      this.formPayload = value
    },
    submit() {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin menambahkan stok gudang baru?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          const form = this.preparePayload()
          this.$store.dispatch('stockOpname/postData', {
            type: '',
            payload: form,
          }).then(() => {
            this.getData()
            this.getDataApproval()
            this.isLoading = false
            successNotification(this, 'Success', 'Stok opname berhasil ditambahkan')
            this.$bvModal.hide('modal-add-stock-opname')
          }).catch(err => {
            this.isLoading = false
            this.messages = err.response.data.meta.messages
            // eslint-disable-next-line no-console
            console.log(err)
          })
        }
      });
    },
    preparePayload() {
      const form = new FormData();
      for (const key in this.formPayload) {
        if (key === 'items') {
          for (let i = 0; i < this.formPayload.items.length; i++) {
            const item = this.formPayload.items[i];
            form.append(`items[${i}][product_uuid]`, item.product_uuid.label);
            form.append(`items[${i}][qty]`, item.qty);
          }
        } else {
          if (this.formPayload[key] != null) {
            form.append(key, this.formPayload[key]);
          }
        }
      }
      // console.log(this.formPayload,'res')
      return form;
    },

  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

#modal-add-stock-opname {
  .modal-dialog {
    max-width: 100%;
    margin: 0;

    .modal-content {
      height: 100vh;

      .modal-body {
        padding: 0;
        overflow-y: scroll;

        .progress-bar-success {
          background-color: #EFF1F5;
          .progress-bar {
            background: linear-gradient(269.89deg, #6CDFD4 0%, #45B6AB 98.81%) !important;
            box-shadow: 0px 6px 8px rgba(44, 129, 120, 0.06);
            border-radius: 20px;
          }
        }

        label {
          font-size: 14px;
          color: $dark;
          font-weight: 600;
        }

        .vs__open-indicator {
          fill: rgba(60, 60, 60, 0.5);
        }

        .custom__input {
          height: 52px;
          background: #EFF1F5;
          border: 1px solid #E4E5EC;
          border-radius: 8px;
        }

        .vs__dropdown-toggle {
          height: 52px;
          background-color: #EFF1F5;
          border: 1px solid #E4E5EC;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
